jQuery.extend({

	oToTopBtn: function(holder){

		// PUBLIC
		var _this               = this;

		var _private = {

			// PRIVATE OBJECTS
			holder  : _this,


			// PRIVATE FUNCTIONS
			setup:function()
			{
				_private.holder             = holder;

			},
			clickListener:function() {
				_private.holder.bind('click', function() {
					$("html, body").animate({
			            scrollTop: 0
			        }, 600);
				});

			},


		};
		// INITIALIZE APPLICATION
		if($(holder).length > 0) {
			_private.setup();
			_private.clickListener();

		}
	}
});
$(function()
{
	$(document).ready(function()
	{
		var oToTopBtn = new $.oToTopBtn($(".o-to-top-btn"));
	});
});
