jQuery.extend({

    mBlockEqual: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder  : _this,
            blockHeight : null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.holder             = holder;
                _private.blockHeight        = 0;

                // var resizeTimer;
                // $(window).resize(function() {
                //     clearTimeout(resizeTimer);
                //     resizeTimer = setTimeout(_private.update, 100);
                // });
            },
            setupBlockHeight:function() {
                
                _private.holder.find(".m-block").each(function(_index, _ell) {

                    var h = $(this).outerHeight();


                    if (_private.blockHeight < h) {
                        _private.blockHeight = h;
                    }

                    
                });

            },
            setBlockHeight:function() {

                _private.holder.find(".m-block").each(function(_index, _ell) {
                    $(this).css("height" , _private.blockHeight + "px");
                });
            },

            update:function() {
                
                
                _private.setup();
                _private.setupBlockHeight();
                _private.setBlockHeight();
            }

        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            _private.setupBlockHeight();
            _private.setBlockHeight();
        }
        // WINDOW RESIZE
        function doResize() {

            $(".equal-h-cols").find(".m-block").each(function(_index, _ell) {
                $(this).css("height" , "auto");
            });
            _private.update();
            
        }
        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 100);
        });

        
    }
});
$(function()
{
    $(document).ready(function()
    {
         var mBlockEqual = new $.mBlockEqual($(".equal-h-cols"));
    });

    
});


jQuery.extend({

    mBlockEqual2: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder  : _this,
            blockHeight : null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.holder             = holder;
                _private.blockHeight        = 0;

                var resizeTimer;
                $(window).resize(function() {
                    clearTimeout(resizeTimer);
                    resizeTimer = setTimeout(_private.update, 100);
                });
            },
            setupBlockHeight:function() {
                
                _private.holder.find(".m-block").each(function(_index, _ell) {

                    var h = $(this).outerHeight();


                    if (_private.blockHeight < h) {
                        _private.blockHeight = h;
                    }

                    
                });

            },
            setBlockHeight:function() {

                _private.holder.find(".m-block").each(function(_index, _ell) {
                    $(this).css("height" , _private.blockHeight + "px");
                });
            },

            update:function() {
                
                
                _private.setup();
                _private.setupBlockHeight();
                _private.setBlockHeight();
            }

        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            _private.setupBlockHeight();
            _private.setBlockHeight();
        }
        // WINDOW RESIZE
        function doResize() {

            $(".equal-h-cols-2").find(".m-block").each(function(_index, _ell) {
                $(this).css("height" , "auto");
            });
            _private.update();
            
        }
        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 100);
        });

        
    }
});
$(function()
{
    $(document).ready(function()
    {
         var mBlockEqual2 = new $.mBlockEqual2($(".equal-h-cols-2"));
    });

    
});
